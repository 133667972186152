<div class="welcome-page">
  <h4 class="text-center w-100 p-2">Welcome!</h4>
  <h4 class="text-center w-100 p-2 pb-4">What would you like to do?</h4>
  <div *ngIf="maintenanceMessage">
    <div class="alert alert-danger">
      {{maintenanceMessage}}
    </div>
  </div>

  <div *ngIf="!loginDisabled">
    <div class="my-3 text-center">
      <button class="btn btn-default btn-normal login-button fs-5" type="submit" (click)="login()">Login</button>
      <div class="w-100">
        <div>I have a username</div>
        <a class="d-block" [routerLink]="['/reset-password']">Reset my password</a>
      </div>
    </div>
  </div>

  <div class="mb-3 text-center">
    <button class="btn btn-default btn-normal login-button fs-5" type="button" (click)="requestLogin()">Request
      retailer
      login</button>
    <div class="w-100">
      My employer is appointed with RPS,<br />I just need a personal login
    </div>
  </div>

  <div class="mb-3 text-center">
    <button class="btn btn-action login-button fs-5" type="button" [routerLink]="['/onboarding']">Start doing business
      with RPS</button>
    <div class="w-100">
      My company has never done business with RPS
    </div>
  </div>

  <div *ngIf="enableEmployeeLogin" class="mb-3 text-center">
    <a [href]="" (click)="login(true)">Employee Login</a>
  </div>
</div>