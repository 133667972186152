import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env';
import { Observable, firstValueFrom, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class InsureZoneService {

  private readonly portalInsureZoneUrl: string;
  private static readonly DefaultHttpOptions = Object.freeze({
    headers: {
      'addAuthorization': 'true',
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
    }
  });

  private static readonly DefaultSecurityHeader = {
    withCredentials: true
  };

  constructor(private readonly http: HttpClient) {
    this.portalInsureZoneUrl = environment.portalApiUri + 'api/InsureZone/';
  }

  getSsoToken(insureZoneSsoUrl: string): Observable<InsureZoneSsoToken | InsureZoneError> {
    return this.http.get<InsureZoneSsoToken>(insureZoneSsoUrl, InsureZoneService.DefaultHttpOptions)
      .pipe(
        catchError(err => this.standardizeHttpError(err))
      );
  }

  private standardizeHttpError(error: HttpErrorResponse): Observable<InsureZoneError> {
    let dataError: Partial<InsureZoneError> = {};
    dataError.errorNumber = error.status;
    dataError.serverMessage = error.message;
    switch (error.status) {
      case 500:
        dataError.userMessage = 'Standard Express Service is temporarily unavailable.';
        break;
      case 401:
      case 404:
      case 403:
        dataError.userMessage = 'User does not have access to Standard Express.';
        break;
      default:
        dataError.userMessage = dataError.serverMessage;
        break;
    }
    return throwError(() => dataError);
  }

  canGrantAccess() {
    return firstValueFrom(this.http.get<null>(this.portalInsureZoneUrl + 'CanGrantAccess', InsureZoneService.DefaultSecurityHeader));
  }

  setupAccess() {
    return firstValueFrom(this.http.get<null>(this.portalInsureZoneUrl + 'SetupAccess', InsureZoneService.DefaultSecurityHeader));
  }
}

export interface InsureZoneSsoToken {
  status: string;
  key: string;
  ticket: string;
  url: string;
}

export interface InsureZoneError {
  errorNumber: number;
  serverMessage: string;
  userMessage: string;
}
